<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
          @click="showFormModal(null)"
          class="px-3 text-capitalize"
          variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد آیتم دیدگاه
        </b-button>
      </b-col>
    </b-row>
    <div v-if="commentItems">
      <div class="row">
        <per-page class="col-sm-12 col-md-3 col-lg-2" v-model="perPage" />
        <!-- <search ref="provincesSearchComponent" class="w-50" v-model="searchInput" /> -->
        <b-form-group
        class="col-md-9 col-lg-10"
          label="نمایش بر اساس نوع آیتم"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            id="btn-radios-2"
            v-model="selectedCommentItemType"
            :options="commentItemTypesOptions"
            :aria-describedby="ariaDescribedby"
            button-variant="outline-primary"
            size="md"
            name="radio-btn-outline"
            @change="getCommentItems"
            buttons
          ></b-form-radio-group>
        </b-form-group>
      </div>
 <b-overlay
   
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
      <b-card body-class="p-0" class="mt-1">
        <table-head
          ref="provincesTableHeadComponent"
          :items="tableHeadings"
          @sort="sort"
        />
        <b-row
          class="py-1 mx-1 border-bottom"
          v-for="commentItem in commentItems"
          :key="commentItem.id"
        >
          <b-col class="d-flex align-items-center pl-0">
            {{ commentItem.name }}
          </b-col>

          <b-col
            class="d-flex align-items-center flex-row-reverse"
            style="direction: ltr"
          >
            {{
              new Date(commentItem.created_at).toLocaleString("fa-IR", {
                timeZone: "Iran",
              })
            }}
          </b-col>
          <b-col class="d-flex align-items-center">
            <b-button
              @click="showConfirmModal(commentItem)"
              variant="flat-danger"
              class="font-weight-bolder"
            >
              <feather-icon icon="Trash2Icon" size="20" />
            </b-button>
            <b-button
              @click="showFormModal(commentItem)"
              variant="flat-secondary"
              class="font-weight-bolder"
            >
              <feather-icon icon="Edit2Icon" size="20" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
 </b-overlay>
      <pagination
        ref="provincesPaginationComponent"
        v-model="page"
        :paginationData="paginationData"
      />
    </div>
    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش آیتم دیدگاه" : "ایجاد آیتم دیدگاه" }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class="">
        <b-form-group class="mt-3">
          <label for="commentItemName" class="text-capitalize"
            >{{ $t("first name") }}:</label
          >
          <b-form-input
            id="commentItemName"
            v-model="commentItemInput.name"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="commentItemType" class="text-capitalize"
            >نوع| type:</label
          >
          <v-select
            id="experience_area"
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="commentItemInput.type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="commentItemInputTypes"
            label="name"
            :reduce="(province) => province.value"
          />
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormRadio,
  BFormRadioGroup,
  BOverlay
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    BFormRadio,
    BFormRadioGroup,
    BOverlay
  },
  data() {
    return {
      showLoading:false,
      selectedCommentItemType: "residence",
      perPage: 10,
      page: 1,
      commentItems: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      tableHeadings: [
        {
          title: "نام",
          slug: "name",
          sortable: true,
        },
        {
          title: "تاریخ ایجاد",
          slug: "created_at",
          sortable: true,
        },
        {
          title: "actions",
          sortable: false,
        },
      ],

      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },

      commentItemInput: {
        name: "",
        type: "",
      },
      editMode: false,
      formModal: false,
      submitLoading: false,
      commentItemInputTypes: [
        { value: "residence", name: "اقامتگاه" },
      ],
      commentItemTypesOptions: [
        { text: "اقامتگاه", value: "residence" },
      ],
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getCommentItems();
    // this.getCompanies();
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getCommentItems();
    },
    perPage(value) {
      this.page = 1;
      this.getCommentItems();
    },
    page(value) {
      this.getCommentItems();
    },
  },
  methods: {
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getCommentItems();
    },

    async getCommentItems() {
      this.showLoading=true
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        paginate: true,
        //  this.searchInput,
        // per_page: this.perPage,
        page: this.page,
        // sort_by: this.sortBy,
        // sort_dir: this.sortDir,
      };

      const requestData = {
        method: "get",
        url: "/admin/comment_items?type=" + this.selectedCommentItemType,
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.commentItems = data.data;
          this.paginationData = data.meta;
          this.showLoading=false
        })
        .catch((err) => {
          console.log(err);
          this.showLoading=false
        });
    },
    showConfirmModal(commentItem) {
      this.$bvModal
        .msgBoxConfirm(
          "آیا از حذف آیتم دیدگاه مطمئن هستید ؟" + `: ${commentItem.name}.`,
          {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (confirmed) => {
          if (confirmed) {
            let provinceIndex = 0;
            this.commentItems = await this.commentItems.filter(
              (provinceItem, index) => {
                if (provinceItem.id == commentItem.id) {
                  provinceIndex = index;
                }
                return provinceItem.id !== commentItem.id;
              }
            );

            const requestData = {
              method: "delete",
              url: `/admin/comment_items/${commentItem.id}`,
            };
            this.$http(requestData)
              .then((response) => {
                this.$bvToast.toast(
                  `آیتم دیدگاه: ${commentItem.name} با موفقیت حذف شد.`,
                  {
                    title: this.$t("Done"),
                    variant: "success",
                    solid: true,
                  }
                );
              })
              .catch((err) => {
                let message = this.$t("Unable to delete!");
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$bvToast.toast(message, {
                  title: this.$t("Error"),
                  variant: "danger",
                  solid: true,
                });
                this.commentItems.splice(provinceIndex, 0, commentItem);
              });
          }
        });
    },
    showFormModal(commentItem) {
      if (commentItem) {
        this.commentItemInput = {
          id: commentItem.id,
          name: commentItem.name,
          type: commentItem.type,
        };
        this.editMode = true;
      } else {
        this.commentItemInput = {
          name: "",
          type: "",
        };
        this.editMode = false;
      }
      this.formModal = true;
    },
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;

      if (!errorMessage && !this.commentItemInput.name) {
        errorMessage = "لطفا نام آیتم دیدگاه را وارد کنید";
      }
      if (!errorMessage && this.commentItemInput.name.length < 2) {
        errorMessage = "نام آیتم دیدگاه نمی تواند کمتر از دو کاراکتر باشد";
      }
      if (!errorMessage && !this.commentItemInput.type) {
        errorMessage = "لطفا اسلاگ آیتم دیدگاه را وارد کنید";
      }

      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.submitLoading = true;

      const requestData = {
        method: this.editMode ? "put" : "post",
        url: this.editMode
          ? `/admin/comment_items/${this.commentItemInput.id}`
          : `/admin/comment_items`,
        data: this.commentItemInput,
      };
this.selectedCommentItemType= this.commentItemInput.type,
      this.$http(requestData)
        .then((response) => {
          let message = "آیتم دیدگاه با موفقیت اضافه شد";
          if (this.editMode) message = "آیتم دیدگاه با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          if (
            !this.editMode &&
            this.commentItems &&
            this.commentItems.length > 0
          ) {
            this.$refs.provincesPaginationComponent.clear();
            // this.$refs.provincesSearchComponent.clear();
            this.$refs.provincesTableHeadComponent.clear();
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }

          this.submitLoading = false;
          this.formModal = false;
          this.getCommentItems();
        })
        .catch((err) => {
          let message = "خطا در ایجاد آیتم دیدگاه";
          if (this.editMode) message = "خطا در ویرایش آیتم دیدگاه";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
